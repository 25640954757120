.editOrder {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  display: flex;
  align-items: center;
  overscroll-behavior-y: contain;
  // overflow-y: auto;

  .editOrderContainer {
    background-color: white;
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: auto;


    .cartItemsContainer {
      padding: 10px 10vw;

      .orderedProductsInfo {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
      }
      h1 {
        font-size: 14px;
      }
      .cartDelete {
        color: #dd1414;
        cursor: pointer;
      }
      // .cartItems {
      //   list-style: none;
      //   padding: 0;
      //   margin-top: 10px;
      //   display: flex;
      //   flex-direction: column;

      //   .cartItem {
      //     display: flex;
      //     justify-content: space-between;
      //     flex-direction: row;
      //     padding: 10px;
      //     .itemLeft {
      //       display: flex;
      //       flex-direction: row;
      //       gap: 20px;

      //       img {
      //         width: 40px;
      //         height: 40px;
      //         object-fit: contain;
      //       }

      //       .details {
      //         h1 {
      //           font-size: 16px;
      //           text-transform: capitalize;
      //         }
      //         .cartDetails {
      //           font-size: 14px;
      //           color: #333;
      //         }
      //         .price {
      //           font-size: 12px;
      //         }
      //       }
      //     }
      //     .itemRight {
      //       display: flex;
      //       align-items: center;
      //     }
      //   }
      // }
    }

    .productsContainer {
      margin: 0 7.5vw;

      h1 {
        font-size: 14px;
        cursor: pointer;
      }
    }

    .customerContainer {
      margin: 0 7.5vw;

      h1 {
        font-size: 14px;
        cursor: pointer;
      }
    }

    .categoriesContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        list-style: none;
        justify-content: center;
        margin: 0;
        padding: 0;

        .categoryItem {
          padding: 10px;
          // margin-bottom: 15px;
          display: flex;
          flex-direction: column;
          gap: 5px;

          li {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .icon {
              padding: 10px;
              border: 2px solid #ffe8e8;
              border-radius: 50%;
              font-size: 1rem;
              color: #dd1414;

              &:hover {
                background-color: #ffe8e8;
              }
            }

            // .categoryIcon{
            //   padding: 10px;
            //   border: 2px solid #ffe8e8;
            //   border-radius: 50%;
            //   font-size: 18px;
            //   color: #dd1414;
            //   height: 25px;

            //   &:hover {
            //     background-color: #ffe8e8;
            //   }
            // }
          }

          .categoryTitle {
            font-size: 10px;
            font-weight: bold;
            color: #999;
            margin-bottom: 5px;
            text-align: center;
            text-transform: capitalize;
          }
        }

        // .active{
        //   li{
        //     .icon{
        //       color: white;
        //       background-color: #dd1414;

        //       &:hover {
        //         background-color: #dd1414;
        //       }
        //     }
        //   }

        //   .categoryTitle {
        //     font-size: 10px;
        //     font-weight: bold;
        //     color: #dd1414;
        //     margin-bottom: 5px;
        //     text-align: center;
        //   }
        // }
      }
    }

    .top {
      padding: 10px;
      margin: 20px;
      display: flex;
      justify-content: space-between;

      .info {
        display: flex;
        flex-direction: column;

        h1 {
          font-size: 20px;
        }
      }

      .closeButton {
        background-color: transparent;
        border: none;
        font-size: 25px;
        cursor: pointer;
        &:hover {
          color: #dd1414;
        }
      }
    }

    .editButton {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      .editOrderButton {
        display: flex;
        align-items: center;
        background-color: #dd1414;
        color: white;
        border: none;
        font-size: 20px;
        font-weight: 500;
        padding: 10px;
        border-radius: 15px;
        cursor: pointer;
        width: fit-content;

        &:hover {
          background-color: #ff1c1c;
        }
      }
    }

    .productsWrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      margin: 0 7.5vw;
      margin-bottom: 50px;
      padding: 20px 0;
      align-items: center;
      flex-wrap: wrap;
    }

    .modalWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      margin: 0 7.5vw;
      padding: 30px 0;
      gap:20px;

      .customerData {
        width: 80%;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        gap: 30px;
  
        .formInput {
          flex: 1;
          label {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 12px;
            margin-bottom: 5px;
          }
  
          input {
            width: 100%;
            border: 1px solid #cccccc;
            border-radius: 5px;
            outline: none;
            padding: 10px;
            box-sizing: border-box;
          }
  
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type="number"] {
            -moz-appearance: textfield;
          }
          select {
            width: 100%;
            border: 1px solid #cccccc;
            border-radius: 5px;
            outline: none;
            padding: 10px;
            box-sizing: border-box;
          }
        }
      } 

      .tipData {
        width: 80%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .title {
          font-size: 12px;
          color: #333;
        }

        .tipButtons {
          display: flex;
          justify-content: center;
          gap: 5vw;
          .tipOption {
            display: flex;
            align-items: center;
            border: 1px solid rgba(139, 139, 139, 0.3);
            border-radius: 2vw;
            padding: 1vw 2vw;
            font-size: clamp(16px,2vw,24px);
            cursor: pointer;
          }

          .active {
            background-color: #dd1414;
            color: white;
          }
        }
      }

      .emailInput {
        width: 80%;
        margin: 0 auto;
        label {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 12px;
          margin-bottom: 5px;
        }

        input {
          width: 100%;
          border: 1px solid #cccccc;
          border-radius: 5px;
          outline: none;
          padding: 10px;
          box-sizing: border-box;
        }
      }

      .deliveryData {
        width: 80%;
        margin: 0 auto;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .deliveryButtons {
          display: flex;
          justify-content: center;
          gap: 5vw;
          .deliveryOption {
            display: flex;
            align-items: center;
            border: 1px solid rgba(139, 139, 139, 0.3);
            border-radius: 2vw;
            padding: 1vw 2vw;
            font-size: clamp(16px,2vw,24px);
            cursor: pointer;
          }

          .active {
            background-color: #dd1414;
            color: white;
          }
        }

        .deliveryDetails {
          display: flex;
          flex-direction: column;
          gap: 20px;
          .homeNumber {
            width: 100%;
            input {
              width: 100%;
              border: 1px solid #cccccc;
              border-radius: 5px;
              outline: none;
              padding: 10px;
              box-sizing: border-box;
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            input[type="number"] {
              -moz-appearance: textfield;
            }
          }
        }
      }

      .commentsData {
        width: 80%;
        margin: 0 auto;
        margin-top: 10px;

        .comments {
          label {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 12px;
            margin-bottom: 5px;
          }

          textarea {
            width: 100%;
            resize: none;
            outline: none;
            border: 1px solid #cccccc;
            border-radius: 5px;
            padding: 5px;
            box-sizing: border-box;
          }
        }
      }

      .voucherData {
        width: 80%;
        margin: 0 auto;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap:10px;

        .vouchers {
          label {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 12px;
            margin-bottom: 5px;
          }

          input {
            width: 100%;
            resize: none;
            outline: none;
            border: 1px solid #cccccc;
            border-radius: 5px;
            padding: 5px;
            box-sizing: border-box;
          }
        }
        button{
          background-color: #dd1414;
          color: white;
          border: none;
          font-weight: 500;
          padding: 5px 10px;
          font-size: 12px;
          border-radius: 15px;
          cursor: pointer;
          width: fit-content;

          &:hover {
            background-color: #ff1c1c;
          }
        }
      }

      .checkoutButton {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 20px;

        button {
          background-color: #dd1414;
          color: white;
          border: none;
          font-weight: 500;
          padding: 10px 20px;
          font-size: 20px;
          border-radius: 15px;
          cursor: pointer;
          width: fit-content;

          &:hover {
            background-color: #ff1c1c;
          }
        }
      }

      // .inputContainer {
      //   display: flex;
      //   flex-direction: row;
      //   justify-content: space-between;
      //   gap: 10vw;
      //   margin: 0 10%;
      //   h1 {
      //     font-size: 18px;
      //     text-align: center;
      //   }

      //   hr {
      //     border: 0.25px solid #999;
      //     width: 80%;
      //     margin: 10px auto;
      //   }

      //   form {
      //     display: flex;
      //     flex-wrap: wrap;
      //     padding-top: 20px;
      //     gap: 30px;
      //     justify-content: space-between;
      //     .formInput {
      //       width: 40%;

      //       label {
      //         display: flex;
      //         align-items: center;
      //         gap: 10px;

      //         .icon {
      //           cursor: pointer;
      //         }
      //       }

      //       input {
      //         width: 100%;
      //         padding: 5px;
      //         border: none;
      //         border-bottom: 1px solid gray;
      //       }
      //       textarea {
      //         width: 100%;
      //       }
      //     }

      //     .checkoutButton {
      //       display: flex;
      //       justify-content: center;
      //       width: 100%;
      //       margin-top: 20px;

      //       button {
      //         background-color: #dd1414;
      //         color: white;
      //         border: none;
      //         font-weight: 500;
      //         padding: 10px;
      //         border-radius: 15px;
      //         cursor: pointer;
      //         width: fit-content;

      //         &:hover {
      //           background-color: #ff1c1c;
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
}
