.promoCard {
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    width: 400px;
    margin: 16px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  
    @media (max-width: 768px) {
      width: 100%;
    }
  
    &:hover {
      transform: scale(1.05);
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    }
  
    .promoCardImage {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  
    .promoCardContent {
      padding: 16px;
  
      .promoCardTitle {
        font-size: 1.5rem;
        // margin-bottom: 8px;
      }
      .promoCardName {
        // font-size: 1.5rem;
        margin-bottom: 8px;
      }
  
      .promoCardDescription {
        color: #555;
        margin-bottom: 16px;
      }
  
      .promoCardDiscount {
        color: #e44d26;
        font-weight: bold;
      }
    }
  }
  
