.itemCard {
  justify-content: space-between;
  margin-top: 20px;
  // width: 10vw;
  min-width: 150px;
  max-width: 200px;
  flex: 0 0 25%;
//   width: 100%;
  //   margin: 5px 10px;


  // @media (max-width: 768px) {
  //   min-width: 200px;
  // }

  
  .itemContainer {
    flex: 1;
    margin: 5px 10px;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border: 2px solid #ffe8e8;
    border-radius: 10px;
    gap: 10px;

    &:hover {
      background-color: #ffe8e8;
    }

    // &:active {
    //   border-color: #dd1414;
    // }

    .itemImage {
      width: 100%;
      flex: 1;
      object-fit: contain;
      background-color: inherit;
    }

    .details {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-size: 16px !important;
        font-weight: 500 !important;
        text-transform: capitalize;
      }
      .price {
        font-size: 12px;
        font-weight: 300;
      }
    }
  }
}
