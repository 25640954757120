.menu {
  cursor: pointer;
  position: fixed;
  padding: 10px;
  background-color: white;
  border: 1px solid #888;
  border-radius: 10px;
  top: 20px;
  left: 20px;
  z-index: 9999;
}

.sidebar {
  position: fixed;
  width: 100%;
  flex: 1;
  border-right: 0.5px solid #efecec;
  min-height: 100vh;
  background-color: white;
  z-index: 9999;

  .top {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      background: none;
      border: none;
      font-size: 20px;
      padding: 10px;
      cursor: pointer;
      color: #333;
      &:hover {
        color: #ff1c1c;
      }
    }

    .logoIcon {
      font-size: 18px;
      color: #dd1414;
    }

    .logo {
      font-size: 16px;
      font-weight: bold;
      color: #dd1414;
    }
  }

  hr {
    height: 0;
    border: 0.5px solid #efecec;
  }

  .center {
    padding-left: 10px;

    .pin {
      display: flex;
      padding: 10px;
      margin: 0 auto;
      gap: 5px;
      .input {
        text-decoration: none;
      }
      .pinBtn {
        background: none;
        border: 1px solid #ff1c1c;
        border-radius: 5%;
        font-size: 20px;
        // padding: 10px;
        cursor: pointer;
        color: #333;
        &:hover {
          color: #ff1c1c;
        }
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      .title {
        font-size: 10px;
        font-weight: bold;
        color: #999;
        margin-top: 15px;
        margin-bottom: 5px;
      }

      li {
        display: flex;
        align-items: center;
        padding: 5px;
        cursor: pointer;

        &:hover {
          background-color: #ece8ff;
        }

        .icon {
          font-size: 18px;
          color: #dd1414;
        }

        span {
          font-size: 13px;
          font-weight: 600;
          color: #888;
          margin-left: 10px;
        }
      }
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    margin: 10px;

    .colorOption {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid #dd1414;
      cursor: pointer;
      margin: 5px;

      &:nth-child(1) {
        background-color: whitesmoke;
      }
      &:nth-child(2) {
        background-color: #333;
      }
      &:nth-child(3) {
        background-color: darkblue;
      }
    }
  }
}
