.modal {
  position: fixed;
  top: 0;
  left: 0;
  background: #25242482;
  height: 100%;
  width: 100vw;
  z-index: 999;
  display: flex;
  align-items: center;

  .modalContainer {
    background-color: white;
    height: 75vh;
    max-width: 80vw;
    min-width: 70vw;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    // gap: 1rem;
    overflow-y: auto;

    .top {
      padding: 10px;
      margin: 1vw;
      display: flex;
      justify-content: space-between;

      .info {
        display: flex;
        flex-direction: column;

        h1 {
          font-size: clamp(10px, 3vw, 20px);
        }
        p{
          font-size: clamp(10px, 2.5vw, 16px);
        }
      }

      .closeButton {
        background-color: transparent;
        border: none;
        font-size: 25px;
        cursor: pointer;
        &:hover {
          color: #dd1414;
        }
      }
    }

    .editButton {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      .editOrderButton {
        display: flex;
        align-items: center;
        background-color: #dd1414;
        color: white;
        border: none;
        font-size: 20px;
        font-weight: 500;
        padding: 10px;
        border-radius: 15px;
        cursor: pointer;
        width: fit-content;

        &:hover {
          background-color: #ff1c1c;
        }
      }
    }

    .modalWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      margin: 0 7.5vw;
      margin-bottom: 30px;
      padding: 20px 0;

      .priceDetails{
        margin:0 10%;
      }

      .orderItems {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
        margin: 0 10%;
      }
    }
  }
}
