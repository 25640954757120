.workedHoursContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  display: flex;
  align-items: center;
  overscroll-behavior-y: contain;
  .workedHoursDetails {
    background-color: white;
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: auto;

    .workedHoursTop {
      padding: 10px;
      margin: 20px;
      display: flex;
      justify-content: space-between;

      h1 {
        font-size: 20px;
      }

      .closeButton {
        background-color: transparent;
        border: none;
        font-size: 25px;
        cursor: pointer;
        &:hover {
          color: #dd1414;
        }
      }
    }

    .workedHoursCenter {
      padding: 10px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      .pin {
        display: flex;
        padding: 10px;
        margin: 0 auto;
        gap: 5px;
        .input {
          text-decoration: none;
        }
        .pinBtn {
          background: none;
          border: 1px solid #ff1c1c;
          border-radius: 5%;
          font-size: 20px;
          // padding: 10px;
          cursor: pointer;
          color: #333;
          &:hover {
            color: #ff1c1c;
          }
        }
      }

      .workedHoursForm {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;
        .formInput {
          padding: 5px;
          display: flex;
          gap: 5px;
          align-items: center;
          //   flex: 1;
          label {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 12px;
            margin-bottom: 5px;
          }

          input {
            width: fit-content;
            border: 1px solid #cccccc;
            border-radius: 5px;
            outline: none;
            padding: 10px;
            box-sizing: border-box;
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type="number"] {
            -moz-appearance: textfield;
          }
          select {
            width: fit-content;
            border: 1px solid #cccccc;
            border-radius: 5px;
            outline: none;
            padding: 10px;
            box-sizing: border-box;
          }
        }
        .addBtn {
          background: none;
          height: 30px;
          border: 1px solid #ff1c1c;
          border-radius: 10%;
          font-size: 20px;
          cursor: pointer;
          color: #333;
          &:hover {
            color: #ff1c1c;
          }
        }
      }
    }
    .workedHoursBottom {
      margin: 20px;
      padding: 10px;
      .person {
        display: flex;
        margin-top: 10px;
        gap: 10px;
        align-items: center;
        .icon {
          font-size: 30px;
          color: red;
        }
        .delete {
          font-size: 20px;
          color: red;
          cursor: pointer;
        }
      }
    }
  }
}
