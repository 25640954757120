.options {
  display: flex;
  width: 100%;
  .optionsContainer {
    width: 100%;
    .optionsBox {
      padding: 2vw;

      h1{
        font-size: clamp(18px, 2.5vw, 24px);
      }
      .optionsItem {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2vw;
        padding: 2vw;
        justify-content: center;
      }
    }
  }
}
