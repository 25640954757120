.app.dark {
  background-color: #111;
  color: rgb(156, 156, 156);
  min-height: 100vh;

  .chartGrid {
    stroke: rgba(228, 228, 228, 0.219);
  }

  .table {
    background-color: #111;

    .tableCell {
      color: gray;
    }
  }

  .navbar {
    color: #999;
    border-color: #333;

    .search {
      border-color: gray;
    }
    .items{
      .item{
        .details{
          .name{
            color:gray;
          }
        }
      }
    }

  }

  .sidebar {
    background-color: #111;
    border-color: #333;

    .top {
      .logo {
        color: #999;
      }
    }
    hr {
      border-color: #333;
    }

    ul {
      li {
        &:hover {
          background-color: #333;
        }

        .icon {
          color: #999;
        }
      }
    }
  }

  .datatable {
    .datagrid {
      color: gray;
      border: none;

      .viewButton,
      .deleteButton,
      .cellWithStatus {
        color: gray;
        border: none;
      }
    }
  }

  input {
    background-color: transparent;
  }

  .modal {
    .modalContainer {
      background-color: #111;
      .modalWrapper {
        box-shadow: none;
      }
      .closeButton {
        color: rgba(204, 2, 2, 0.5);
        &:hover {
          color: rgba(204, 0, 0, 0.75);
        }
      }
    }
  }

  .datePicker {
    input {
      background: transparent;
      color: #999;
    }
  }

  p {
    color: #999;
  }
  h5 {
    color: #777;
  }
  input {
    background: transparent;
    color: #999;
  }

  .editOrder,
  .newOrder {
    .editOrderContainer,
    .newOrderContainer {
      background-color: #111;
      color: rgb(156, 156, 156);

      .closeButton {
        color: rgba(204, 2, 2, 0.5);
        &:hover {
          color: rgba(204, 0, 0, 0.75);
        }
      }
      .productsWrapper,
      .modalWrapper {
        box-shadow: none;
      }
    }
  }

  .duo {
    background-color: rgba(153, 153, 153, 0.85);
    color: #dbdbdb;
    .duoContainer {
      background-color: rgba(153, 153, 153, 0.85);
      color: #dbdbdb;
      .duoTop {
        .duoLeft {
          .active {
            background-color: #333;
          }
          .sizeOption {
            border: 2px solid white;
            .medium {
              color: #fff;
            }
            .large {
              color: #fff;
            }
          }
          .sizeTitle {
            color: #fff;
          }
          .addToCart {
            .quantityContainer {
              border: 1px solid white;
              background-color: #333;
              .icon {
                color: #fff;
              }
              .quantity {
                color: #fff;
              }
            }
          }
          .addToCartButton {
            background-color: rgba(204, 0, 0, 0.75);
            &:hover {
              background-color: rgba(204, 0, 0, 1);
            }
          }
        }
        .duoRight {
        }
      }

      .duoBottom {
        .duoPizza {
          ul.pizzas li label {
            background-color: transparent;
            border: 2px solid white;
            color: #ffffff;
          }

          ul.pizzas li input[type="checkbox"]:checked + label {
            border: 2px solid #333;
            background-color: #333;
            color: #ffffff;
            transition: all 0.2s;
          }
          ul.pizzas li input[type="checkbox"]:focus + label {
            border: 2px solid #333;
          }
        }
        .duoIngredients {
          ul.ingredients li label {
            background-color: transparent;
            border: 2px solid white;
            color: #ffffff;
          }

          ul.ingredients li input[type="checkbox"]:checked + label {
            border: 2px solid #333;
            background-color: #333;
            color: #ffffff;
            transition: all 0.2s;
          }
          ul.ingredients li input[type="checkbox"]:focus + label {
            border: 2px solid #333;
          }
        }
        .duoAddons {
          ul.addons li label {
            background-color: transparent;
            border: 2px solid white;
            color: #ffffff;
          }

          ul.addons li input[type="checkbox"]:checked + label {
            border: 2px solid #333;
            background-color: #333;
            color: #ffffff;
            transition: all 0.2s;
          }
          ul.addons li input[type="checkbox"]:focus + label {
            border: 2px solid #333;
          }
        }
      }
    }
  }

  .itemModal {
    background-color: rgba(153, 153, 153, 0.85);
    color: #dbdbdb;
    .itemModalContainer {
      .details {
        .detailsLeft {
          .active {
            background-color: #333;
          }
          .sizeOption {
            border: 2px solid white;
            .medium {
              color: #fff;
            }
            .large {
              color: #fff;
            }
          }
          .sizeTitle {
            color: #fff;
          }
          .addToCart {
            .quantityContainer {
              border: 1px solid white;
              background-color: #333;
              .icon {
                color: #fff;
              }
              .quantity {
                color: #fff;
              }
            }
          }
          .addToCartButton {
            background-color: rgba(204, 0, 0, 0.75);
            &:hover {
              background-color: rgba(204, 0, 0, 1);
            }
          }
        }
        .detailsRight {
        }
      }
      .options {
        ul.ingredients li label {
          background-color: transparent;
          border: 2px solid white;
          color: #ffffff;
        }

        ul.ingredients li input[type="checkbox"]:checked + label {
          border: 2px solid #333;
          background-color: #333;
          color: #ffffff;
          transition: all 0.2s;
        }
        ul.ingredients li input[type="checkbox"]:focus + label {
          border: 2px solid #333;
        }
      }
    }
  }

  .modalWrapper {
    .contactData {
      .formInput {
        input {
          background: transparent;
          color: #999;
        }
        textarea {
          color: #999;
          background: transparent;
          &:active {
            outline: #dd1414;
          }
        }
      }
    }
    .emailInput {
      input {
        background: transparent;
        color: #999;
      }
    }

    .deliveryData {
      .deliveryButtons {
        .active {
          border: 2px solid white;
        }
        .deliveryOption {
          background-color: #333;
          color: #999;
        }
      }
      select {
        background: transparent;
      }
      .homeNumber {
        input {
          background: transparent;
          color: #999;
        }
      }
      .css-13cymwt-control,
      .css-1dimb5e-singleValue {
        background: transparent;
        color: #999;
      }
    }

    .commentsData {
      .comments {
        textarea {
          color: #999;
          background: transparent;
          &:active {
            outline: #dd1414;
          }
        }
      }
    }
    .tipData{
      .title{
        color:#999;
      }
      .tipButtons {
        .active {
          border: 2px solid white;
        }
        .tipOption {
          background-color: #333;
          color: #999;
        }
      }
    }
    .customerData{
      .formInput{
        input{
         background: transparent;
         color: #999;
        }
        select{
          background: transparent;
          color: #999;
        }
      }
    }
  }
}
