.hompageModalContainer {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-y: auto;

  .modalBox {
    margin: auto;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
    min-width: fit-content;

    .titleModalHompage {
      width: 100%;
      display: flex;
      gap: 50px;
      justify-content: space-between;

      .leftTitle {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 20px;
      }

      .rightTitle {
        .closeButton {
          padding-right: 20px;
          background-color: transparent;
          border: none;
          font-size: 20px;
          cursor: pointer;

          &:hover {
            color: #dd1414;
          }
        }
      }
    }

    .modalBoxContent {
      width: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      gap: 20px;

      p {
        max-width: 400px;
        text-align: center;
      }

      .addToCartButton {
        max-width: 150px;
        background-color: #dd1414;
        color: white;
        border: none;
        font-weight: 500;
        padding: 1vw;
        border-radius: 2vw;
        cursor: pointer;

        &:hover {
          background-color: #ff1c1c;
        }
        &:disabled {
          background-color: #333;
          cursor: not-allowed;
        }
      }

      .options {
        text-align: center;

        ul.items {
          list-style: none;
          padding: 20px;
          margin: 0 auto;
          max-width: 900px;
        }

        ul.items li {
          display: inline;
          margin-right: 5px;
        }

        ul.items li label {
          display: inline-block;
          background-color: rgba(255, 255, 255, 0.9);
          border: 2px solid rgba(139, 139, 139, 0.3);
          color: #adadad;
          border-radius: 25px;
          white-space: nowrap;
          margin: 3px 0px;
          cursor: pointer;
          transition: all 0.2s;

          padding: 8px 12px;
          cursor: pointer;
        }

        ul.items li label::before {
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          font-weight: 900;
          font-size: 12px;
          padding: 2px 6px 2px 2px;
          transition: transform 0.3s ease-in-out;
        }

        ul.items li input[type="checkbox"]:checked + label::before {
          transition: transform 0.3s ease-in-out;
        }

        ul.items li input[type="checkbox"]:checked + label {
          border: 2px solid #dd1414;
          background-color: #dd1414;
          color: #fff;
          transition: all 0.2s;
        }

        ul.items li input[type="checkbox"] {
          display: absolute;
        }

        ul.items li input[type="checkbox"] {
          position: absolute;
          opacity: 0;
        }

        ul.items li input[type="checkbox"]:focus + label {
          border: 2px solid #ff1c1c;
        }

        .optionsBox {
          display: flex;
          gap: 5px;

          .sizeOption {
            display: flex;
            align-items: center;
            border: 2px solid rgba(139, 139, 139, 0.3);
            border-radius: 2vw;
            padding: 0.5vw 1vw;
            cursor: pointer;
          }

          .active {
            background-color: #dd1414;
            color: white;

            .medium {
              color: white;
            }

            .large {
              color: white;
            }
          }
        }
      }

      .selectedItems {
        margin-top: 20px;
        text-align: center;

        div.items {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
          margin: 10px;
          justify-content: center;
          .activeItem {
            cursor: pointer;
            border: 1px solid #ff1c1c;
            border-radius: 10px;
            background-color: #f8f8f8;
            padding: 15px;
            // margin: 10px;
            text-align: center;
            .itemName {
              font-size: 0.8em;
              font-weight: bold;
            }

            .itemDetail {
              font-size: 0.6em;
              // margin-top: 8px;
              color: #666;
            }
          }

          .item {
            cursor: pointer;
            background-color: #f8f8f8;
            border: 1px solid #ddd;
            border-radius: 10px;
            padding: 15px;
            // margin: 10px;
            text-align: center;
          }

          .itemName {
            font-size: 0.8em;
            font-weight: bold;
          }

          .itemDetail {
            font-size: 0.6em;
            // margin-top: 8px;
            color: #666;
          }
        }

        button {
          margin-right: 10px;
          background-color: none;
          border: 2px solid #ff1c1c;
          color: #333;
          font-weight: 500;
          padding: 8px;
          border-radius: 8px;
          cursor: pointer;

          &:hover {
            background-color: #ff1c1c;
          }
        }
        .active {
          background-color: #dd1414;
          color: #fff;
        }

        p {
          margin: 2px 0;
          // font-size: 14px;
          // line-height: 1.5;
        }
      }
    }
  }

  .backgroundModal {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: -1;
    background-color: rgb(0, 0, 0, 0.5);
  }
}
