.workdayInfo {
  border: 1px solid #333;
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
  align-items: center;

  .left {
    display: flex;
    justify-content: center;
    .icon {
      font-size: 30px;
      color: red;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 12px;
      color: #999;
    }
    p {
      font-size: 20px;
      font-weight: 900;
      color: #333;
    }
  }
}
