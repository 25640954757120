.dashboard {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  .dashboardTop {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;

    .dashboardData {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      padding: 20px;
      gap: 10px;
      justify-content: center;
      align-items: center;

 .workedHours{
        margin: 10px;
        button {
          display: flex;
          align-items: center;
          background-color: #dd1414;
          color: white;
          border: none;
          font-size: 14px;
          font-weight: 500;
          padding: 10px;
          border-radius: 15px;
          cursor: pointer;
          width: fit-content;

          &:hover {
            background-color: #ff1c1c;
          }
        }
      }

.chooseEmployees {
        display: flex;
        gap: 10px;
        margin-bottom: 10px;
        .formInput {
          padding: 5px;
          flex: 1;
          label {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 12px;
            margin-bottom: 5px;
          }

          input {
            width: 100%;
            border: 1px solid #cccccc;
            border-radius: 5px;
            outline: none;
            padding: 10px;
            box-sizing: border-box;
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type="number"] {
            -moz-appearance: textfield;
          }
          select {
            width: 100%;
            border: 1px solid #cccccc;
            border-radius: 5px;
            outline: none;
            padding: 10px;
            box-sizing: border-box;
          }
        }
      }

.pin {
        display: flex;
        padding: 10px;
        margin: 0 auto;
        gap: 5px;
        .input {
          text-decoration: none;
        }
        .pinBtn {
          background: none;
          border: 1px solid #ff1c1c;
          border-radius: 5%;
          font-size: 20px;
          // padding: 10px;
          cursor: pointer;
          color: #333;
          &:hover {
            color: #ff1c1c;
          }
        }
      }

      .datePicker {
        margin-bottom: 10px;
        input {
          // background-color: white;
        }
      }
      .dashboardDataTop {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
      }
      .dashboardDataBottom {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
      }

      .showProducts {
display: flex;
        gap:20px;
        margin-top: 10px;
        button {
          display: flex;
          align-items: center;
          background-color: #dd1414;
          color: white;
          border: none;
          font-size: 14px;
          font-weight: 500;
          padding: 10px;
          border-radius: 15px;
          cursor: pointer;
          width: fit-content;

          &:hover {
            background-color: #ff1c1c;
          }
        }
      }
    }
    .addOrderButton {
      display: flex;
      align-items: center;
      background-color: #dd1414;
      color: white;
      border: none;
      font-size: 20px;
      font-weight: 500;
      padding: 10px;
      border-radius: 15px;
      cursor: pointer;
      width: fit-content;

      &:hover {
        background-color: #ff1c1c;
      }
    }
  }

  .dashboardBottom {
    display: flex;
    flex-direction: column;
    gap: 5vw;
    margin-bottom: 5vw;
    .activeOrders {
      padding: 10px 0;
      margin: 0px 20px;
      .title {
        margin-left: 20px;
        width: 100px;
        padding: 5px;
        font-size: 14px;
        font-weight: bold;
        border-radius: 10px;
      }
      .list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 10px;
        flex-wrap: wrap;
      }
  .listMode{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width:fit-content;
        max-width: 100%;
        gap: 5px;
        margin: 0 auto;
      }
    }
  }

  .dashboardRight {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    .dashboardStats {
      height: 30px;
    }
  }

  .orderItem {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5px;
    border: 1px solid #333;
    border-radius: 10px;
    padding: 5px;
    margin: 10px auto;
  }
}
