.card {
  justify-content: space-between;
  margin-top: 10px;

.cardListNormal, .cardListBold, .cardListNormalfalse, .cardListBoldfalse {
    padding: 5px;
    display: flex;
    flex-direction: column;
    border: 2px solid #666;
    border-radius: 10px;
    gap: 4px;
    cursor: pointer;
     span {
      display: flex;
      flex-direction: row;
      font-size: 12px;
      text-transform: capitalize;
      // gap: 5px;
      justify-content: space-between;
      .left {
        display: flex;
      }
      .right {
        display: flex;
flex-wrap: wrap;
        margin-left: 20px;
        gap: 10px;
        .ready {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: darkorange;
        }
        .preparation {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: darkkhaki;
        }
        .delivered {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: limegreen;
        }
        .cancelled {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: red;
        }
      }
    }
    .alert {
      color: red;
      font-weight: 700;
      font-size: 12px;
    }

    .icon {
      font-size: 20px;
      color: red;
    }
  }

  .cardContainerNormal, .cardContainerBold, .cardContainerNormalfalse, .cardContainerBoldfalse {
    min-height: 120px;
  //  flex: 1;
    margin: 5px 10px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    border: 2px solid #666;
    border-radius: 10px;
    gap: 10px;
    max-width: 250px;


    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      .left {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        h1 {
          font-size: 10px;
        }
        h2 {
          color: red;
          font-size: 12px;
        }
        p {
          font-size: 10px;
        }
      }
      .ready {
        margin-left: 20px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: darkorange;
      }
      .pending {
        margin-left: 20px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: aqua;
      }
      .preparation {
        margin-left: 20px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: darkkhaki;
      }
      .delivered {
        margin-left: 20px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: limegreen;
      }
      .cancelled {
        margin-left: 20px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: red;
      }
    }

    .statusChange {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;
      font-size: 10px;
      font-weight: bold;
  //      max-width: 20vw;
      margin: auto;

      .status {
        cursor: pointer;
        padding: 3px;
        border-radius: 10px;
        border: 2px solid #ffe8e8;
        color: red;

        &:hover {
          background-color: #ffe8e8;
        }
      }
      .active {
        background-color: red;
        color: white;
        border: 2px solid red;
        &:hover {
          background-color: red;
        }
      }
      .passive {
        cursor: pointer;
        padding: 5px;
        border-radius: 10px;
        color: aqua;
        border: 3px solid aqua;
      }
    }

    .comments {
      display: flex;
      flex-direction: column;
      max-width: 250px;
      flex-wrap: wrap;
      font-size: 14px;
      gap: 5px;
font-weight: 700;
      color: red;
      .icon {
        font-size: 20px;
        color: red;
      }
    }

    .nip {
      display: flex;
      // flex-direction: column;
      margin: auto;
      // max-width: 250px;
      flex-wrap: wrap;
      font-size: 14px;
      // gap: 5px;
      .icon {
        font-size: 20px;
        color: red;
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      gap: 5px;

      .left {
        display: flex;
        flex-direction: column;
        gap: 5px;
        span {
          display: flex;
          max-width: 150px;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          font-size: 12px;
          text-transform: capitalize;
          gap: 5px;

 .alert {
            color: red;
            font-weight: 700;
            font-size: 14px;
          }

          .icon {
            font-size: 20px;
            color: red;
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        gap: 5px;

        span {
          display: flex;
          max-width: 150px;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          font-size: 12px;
          text-transform: capitalize;
          gap: 5px;

          a{
            text-decoration: none;
            color: inherit;
          } 

          .icon {
            font-size: 20px;
            color: red;
          }
          .iconClick {
            cursor: pointer;
          }
          .iconGreen {
            color: green;
          }
          .iconRed{
            color: red;
          }
        }
      }
    }
  }
.cardContainerBold, .cardListBold {
    border: 3px solid limegreen;
  }
 .cardContainerNormalfalse, .cardListNormalfalse, .cardContainerBoldfalse, .cardListBoldfalse {
    border: 3px solid red;
  }
}
