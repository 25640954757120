.edit {
    width: 100%;
    display: flex;
  
    .editContainer {
      flex: 6;
  
      .top,
      .bottom {
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 10px;
        margin: 20px;
        display: flex;
        flex-direction: column;
        gap:10px;
        align-items: center;
        justify-content: center;

        .addOrderButton {
          display: flex;
          align-items: center;
          background-color: #dd1414;
          color: white;
          border: none;
          font-size: 20px;
          font-weight: 500;
          padding: 10px;
          border-radius: 15px;
          cursor: pointer;
          width: fit-content;
    
          &:hover {
            background-color: #ff1c1c;
          }
        }
  
        h1 {
          color: lightgray;
          font-size: 20px;
        }
  
        .left {
          flex: 1;
          text-align: center;
  
          img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
  
        .right {
          flex: 2;
          padding: 10px 30px; 
  
  
          form {
            display: flex;
            flex-wrap: wrap;
            gap: 30px;
            justify-content: space-between;
  
            .formInput {
              width: 40%;
  
              label {
                display: flex;
                align-items: center;
                gap: 10px;
  
                .icon {
                  cursor: pointer;
                }
              }
  
              input {
                width: 100%;
                padding: 5px;
                border: none;
                border-bottom: 1px solid gray;
              }
            }
  
            button {
              width: 150px;
              padding: 10px;
              border: none;
              background-color: teal;
              color: white;
              font-weight: bold;
              cursor: pointer;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  