.duo {
  position: fixed;
  top: 0;
  left: 0;
  background: #242323d9;
  height: 100%;
  width: 100vw;
  z-index: 9999;
  display: flex;
  align-items: center;

  .duoContainer {
    background-color: white;
    height: 75vh;
    width: 80vw;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow-y: auto;

    .duoTop {
      .duoLeft {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5vw;
        .options {
          .optionsBox {
            display: flex;
            gap: 5px;
          }
        }

        .sizeOption {
          display: flex;
          align-items: center;
          border: 2px solid rgba(139, 139, 139, 0.3);
          border-radius: 2vw;
          padding: 0.5vw 1vw;
          cursor: pointer;

          .medium {
            font-size: 35px;
            color: #dd1414;
          }
          .large {
            font-size: 45px;
            color: #dd1414;
          }
        }

        .active {
          background-color: #dd1414;
          color: white;
          .medium {
            color: white;
          }
          .large {
            color: white;
          }
        }

        .addToCart {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 10px;

          .quantityContainer {
            display: flex;
            flex-direction: row;
            border: 1px solid #dd1414;
            border-radius: 10px;

            .icon {
              color: #dd1414;
              cursor: pointer;
            }
          }

          .price {
          }

          .addToCartButton {
            background-color: #dd1414;
            color: white;
            border: none;
            font-weight: 500;
            padding: 10px;
            border-radius: 15px;
            cursor: pointer;

            &:hover {
              background-color: #ff1c1c;
            }
          }
        }
      }
      .duoRight {
        display: flex;
        justify-content: flex-end;
        .closeButton {
          background-color: transparent;
          border: none;
          font-size: 25px;
          cursor: pointer;
          &:hover {
            color: #dd1414;
          }
        }
      }
    }
    .duoBottom {
      display: flex;
      flex-wrap: wrap;
      // align-items: center;
      justify-content: center;

.options {
width:100%;
        ul.ingredients {
          list-style: none;
          padding: 20px;
        }
        ul.ingredients li {
          display: inline;
          margin-right: 5px;

        }
        ul.ingredients li label {
          display: inline-block;
          background-color: rgba(255, 255, 255, 0.9);
          border: 2px solid rgba(139, 139, 139, 0.3);
          color: #adadad;
          border-radius: 25px;
          white-space: nowrap;
          margin: 3px 0px;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          -webkit-tap-highlight-color: transparent;
          transition: all 0.2s;
        }
  
        ul.ingredients li label {
          padding: 8px 12px;
          cursor: pointer;
        }
  
        ul.ingredients li label::before {
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          font-weight: 900;
          font-size: 12px;
          padding: 2px 6px 2px 2px;
          transition: transform 0.3s ease-in-out;
        }
  
        ul.ingredients li input[type="checkbox"]:checked + label::before {
          transition: transform 0.3s ease-in-out;
        }
  
        ul.ingredients li input[type="checkbox"]:checked + label {
          border: 2px solid #dd1414;
          background-color: #dd1414;
          color: #fff;
          transition: all 0.2s;
        }
  
        ul.ingredients li input[type="checkbox"] {
          display: absolute;
        }
        ul.ingredients li input[type="checkbox"] {
          position: absolute;
          opacity: 0;
        }
        ul.ingredients li input[type="checkbox"]:focus + label {
          border: 2px solid #ff1c1c;
        }
      }

      hr {
        border: 1px solid rgba(51, 51, 51, 0.7);
        height: 100%;
        margin: 0 10px;
      }

      .duoPizza {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        .duoIngredients,
        .duoAddons {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        ul.pizzas,
        ul.addons,
        ul.ingredients {
          list-style: none;
          padding: 20px;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
        ul.pizzas li,
        ul.addons li,
        ul.ingredients li {
          display: inline;
          margin-right: 5px;
        }
        ul.pizzas li label,
        ul.addons li label,
        ul.ingredients li label {
          display: inline-block;
          background-color: rgba(255, 255, 255, 0.9);
          border: 2px solid rgba(139, 139, 139, 0.3);
          color: #adadad;
          border-radius: 25px;
          white-space: nowrap;
          margin: 3px 0px;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          -webkit-tap-highlight-color: transparent;
          transition: all 0.2s;
        }

        ul.pizzas li label,
        ul.addons li label,
        ul.ingredients li label {
          padding: 8px 12px;
          cursor: pointer;
        }

        ul.pizzas li label::before,
        ul.addons li label::before,
        ul.ingredients li label::before {
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          font-weight: 900;
          font-size: 12px;
          padding: 2px 6px 2px 2px;
          transition: transform 0.3s ease-in-out;
        }

        ul.pizzas li input[type="checkbox"]:checked + label::before,
        ul.addons li input[type="checkbox"]:checked + label::before,
        ul.ingredients li input[type="checkbox"]:checked + label::before {
          transition: transform 0.3s ease-in-out;
        }

        ul.pizzas li input[type="checkbox"]:checked + label,
        ul.addons li input[type="checkbox"]:checked + label,
        ul.ingredients li input[type="checkbox"]:checked + label {
          border: 2px solid #dd1414;
          background-color: #dd1414;
          color: #fff;
          transition: all 0.2s;
        }

        ul.pizzas li input[type="checkbox"],
        ul.addons li input[type="checkbox"],
        ul.ingredients li input[type="checkbox"] {
          display: absolute;
        }
        ul.pizzas li input[type="checkbox"],
        ul.addons li input[type="checkbox"],
        ul.ingredients li input[type="checkbox"] {
          position: absolute;
          opacity: 0;
        }
        ul.pizzas li input[type="checkbox"]:focus + label,
        ul.addons li input[type="checkbox"]:focus + label,
        ul.ingredients li input[type="checkbox"]:focus + label {
          border: 2px solid #ff1c1c;
        }
      }

      hr {
        border: 1px solid rgba(51, 51, 51, 0.7);
        height: 100%;
        margin: 0 10px;
      }

      .duoPizza {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        .duoIngredients,
        .duoAddons {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        ul.pizzas,
        ul.addons,
        ul.ingredients {
          list-style: none;
          padding: 20px;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
        ul.pizzas li,
        ul.addons li,
        ul.ingredients li {
          display: inline;
          margin-right: 5px;
        }
        ul.pizzas li label,
        ul.addons li label,
        ul.ingredients li label {
          display: inline-block;
          background-color: rgba(255, 255, 255, 0.9);
          border: 2px solid rgba(139, 139, 139, 0.3);
          color: #adadad;
          border-radius: 25px;
          white-space: nowrap;
          margin: 3px 0px;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          -webkit-tap-highlight-color: transparent;
          transition: all 0.2s;
        }

        ul.pizzas li label,
        ul.addons li label,
        ul.ingredients li label {
          padding: 8px 12px;
          cursor: pointer;
        }

        ul.pizzas li label::before,
        ul.addons li label::before,
        ul.ingredients li label::before {
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          font-weight: 900;
          font-size: 12px;
          padding: 2px 6px 2px 2px;
          transition: transform 0.3s ease-in-out;
        }

        ul.pizzas li input[type="checkbox"]:checked + label::before,
        ul.addons li input[type="checkbox"]:checked + label::before,
        ul.ingredients li input[type="checkbox"]:checked + label::before {
          transition: transform 0.3s ease-in-out;
        }

        ul.pizzas li input[type="checkbox"]:checked + label,
        ul.addons li input[type="checkbox"]:checked + label,
        ul.ingredients li input[type="checkbox"]:checked + label {
          border: 2px solid #dd1414;
          background-color: #dd1414;
          color: #fff;
          transition: all 0.2s;
        }

        ul.pizzas li input[type="checkbox"],
        ul.addons li input[type="checkbox"],
        ul.ingredients li input[type="checkbox"] {
          display: absolute;
        }
        ul.pizzas li input[type="checkbox"],
        ul.addons li input[type="checkbox"],
        ul.ingredients li input[type="checkbox"] {
          position: absolute;
          opacity: 0;
        }
        ul.pizzas li input[type="checkbox"]:focus + label,
        ul.addons li input[type="checkbox"]:focus + label,
        ul.ingredients li input[type="checkbox"]:focus + label {
          border: 2px solid #ff1c1c;
        }
      }
    }
  }
}
